export default {
    methods: {
        isValidAttribute(evaluationResult, attributeId) {
            const firstBehaviorPosition = (attributeId - 1) * 3;
            let countValidBehaviors = 0;

            for (let behavior = 1; behavior < 4; behavior++) {
                const behaviorValue = evaluationResult[`a${firstBehaviorPosition + behavior}`];

                if (behaviorValue) {
                    countValidBehaviors++;
                }
            }

            return countValidBehaviors > 1;
        },

        getBehaviorValue(evaluationResult, behaviorId, attributeId) {
            if (!attributeId) {
                attributeId = this.getAttributeFromBehaviorId(behaviorId);
            }

            if (!this.isValidAttribute(evaluationResult, attributeId)) return 0;

            const behaviorValue = evaluationResult[`a${behaviorId}`];

            return behaviorValue;
        },

        getAttributeFromBehaviorId(behaviorId) {
            return 1 + Math.floor((behaviorId - 1) / 3);
        }
    }
};
