import axios from '@/plugins/axios';

export default class BaseApiServices {
    constructor() {
        if (this.constructor == BaseApiServices) {
            throw new Error("Abstract classes can't be instantiated.");
        }
    }

    getAxiosInstance() {
        return axios;
    }

    async updateOrCreateItem({ urlMain, urlAppend, formData }) {
        const data = await this.getAxiosInstance().post(`${urlMain}${urlAppend}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });

        return data;
    }
}
