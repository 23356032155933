import EvaluationsAPIService from '@/services/EvaluationsAPIService';
import evaluationResultMixin from '@/mixins/evaluationResultMixin';

import numberHelper from '@/helpers/numbers';

/**
    evaluationTypes Ids
        selfAppraisal: 1,
        responsible: 2,
        collaborators: 3,
        pairs: 4
*/
export default {
    namespaced: true,
    state: {
        userEvaluations: {
            userId: null,
            companyId: null,
            gameId: null,
            data: []
        }
    },
    getters: {
        getUserEvaluations: state => {
            return state.userEvaluations;
        },

        getBehaviorEvaluationTypeDataStats: (state, getters) => {
            const COLUMNS_COUNT = 5;

            // 0-Autovaloración, 1-Responsable 1, 2-Responsable 2, 3-Pares, 4-Colaboradores
            const getColumnIndex = evaluationResult => {
                switch (evaluationResult.evaluation_type_id) {
                    case 1:
                        return 0;
                    case 2:
                        return 4;
                    case 3:
                        return evaluationResult?.extras?.isResponsible1 ? 1 : 2;
                    case 4:
                        return 3;

                    default:
                        return -1;
                }
            };

            const columnIndexToShow = {};

            const BEHAVIORS_COUNT = 18;
            const behaviorsMatrix = Array.from({ length: BEHAVIORS_COUNT }, () => new Array(COLUMNS_COUNT));
            const behaviorsMatrixAvg = Array.from({ length: BEHAVIORS_COUNT }, () => new Array(COLUMNS_COUNT));

            const evaluationMatrix = getters.getUserEvaluations?.data;

            for (let behavior = 1; behavior <= BEHAVIORS_COUNT; behavior++) {
                evaluationMatrix?.forEach(evaluationResult => {
                    const columnIndex = getColumnIndex(evaluationResult);

                    columnIndexToShow[columnIndex] = true;

                    let item = behaviorsMatrix[behavior - 1][columnIndex];

                    if (!item) {
                        item = { sum: 0, validCount: 0, invalidCount: 0 };
                    }

                    const currentValue = evaluationResultMixin.methods.getBehaviorValue(evaluationResult, behavior);
                    if (currentValue) {
                        item.sum += currentValue;
                        item.validCount++;
                    } else {
                        item.invalidCount++;
                    }

                    behaviorsMatrix[behavior - 1][columnIndex] = item;
                });
            }

            for (let indexColumn = 0; indexColumn < COLUMNS_COUNT; indexColumn++) {
                for (let indexRow = 0; indexRow < BEHAVIORS_COUNT; indexRow++) {
                    const item = behaviorsMatrix[indexRow][indexColumn];
                    behaviorsMatrixAvg[indexRow][indexColumn] = item?.validCount
                        ? numberHelper.round(item.sum / item?.validCount)
                        : 0;
                }
            }

            return { behaviorsMatrix, behaviorsMatrixAvg, columnIndexToShow, COLUMNS_COUNT };
        },

        getAttributesDispersionValues: (state, getters) => {
            const COUNT_ATTRIBUTES = 6;
            const COUNT_DISTINCT_VALUES = 7;
            const pairsDispersion = Array.from({ length: COUNT_ATTRIBUTES }, () =>
                Array(COUNT_DISTINCT_VALUES).fill(0)
            );
            const collaboratorsDispersion = Array.from({ length: COUNT_ATTRIBUTES }, () =>
                Array(COUNT_DISTINCT_VALUES).fill(0)
            );
            const evaluationsTypesAvailable = { pairs: false, collaborators: false };

            const evaluationMatrix = getters.getUserEvaluations?.data;
            const BEHAVIORS_COUNT = 18;
            for (let behavior = 1; behavior <= BEHAVIORS_COUNT; behavior++) {
                evaluationMatrix?.forEach(evaluationResult => {
                    // evaluation_type_id: responsible: 2, pairs: 4
                    if (evaluationResult.evaluation_type_id !== 2 && evaluationResult.evaluation_type_id !== 4) {
                        return;
                    }

                    const currentAttributeId = evaluationResultMixin.methods.getAttributeFromBehaviorId(behavior);

                    /* For show the dispersion only from valid behaviors
                    const currentBehaviorValue = evaluationResultMixin.methods.getBehaviorValue(
                        evaluationResult,
                        behavior,
                        currentAttributeId
                    ); */
                    const currentBehaviorValue = evaluationResult[`a${behavior}`];

                    if (evaluationResult.evaluation_type_id === 2) {
                        evaluationsTypesAvailable.collaborators = true;
                        collaboratorsDispersion[currentAttributeId - 1][currentBehaviorValue]++;
                    } else {
                        evaluationsTypesAvailable.pairs = true;
                        pairsDispersion[currentAttributeId - 1][currentBehaviorValue]++;
                    }
                });
            }

            return { pairsDispersion, collaboratorsDispersion, evaluationsTypesAvailable };
        },

        getAttributeEvaluationTypeDataStats: (state, getters) => {
            const ATTRIBUTES_COUNT = 6;
            const EVALUATIONS_TYPE_COUNT = 4;
            const attributesAvgMatrix = Array.from(
                { length: ATTRIBUTES_COUNT },
                () => new Array(EVALUATIONS_TYPE_COUNT)
            );
            const evaluationTypesExistIdMap = {};

            const BEHAVIORS_COUNT = 18;
            const behaviorsMatrix = Array.from({ length: BEHAVIORS_COUNT }, () => new Array(EVALUATIONS_TYPE_COUNT));
            const behaviorsMatrixAvg = Array.from({ length: BEHAVIORS_COUNT }, () => new Array(EVALUATIONS_TYPE_COUNT));

            const evaluationMatrix = getters.getUserEvaluations?.data;

            for (let behavior = 1; behavior <= BEHAVIORS_COUNT; behavior++) {
                evaluationMatrix?.forEach(evaluationResult => {
                    evaluationTypesExistIdMap[evaluationResult.evaluation_type_id] = true;

                    let item = behaviorsMatrix[behavior - 1][evaluationResult.evaluation_type_id - 1];

                    if (!item) {
                        item = { sum: 0, validCount: 0, invalidCount: 0 };
                    }

                    const currentValue = evaluationResultMixin.methods.getBehaviorValue(evaluationResult, behavior);
                    if (currentValue) {
                        item.sum += currentValue;
                        item.validCount++;
                    } else {
                        item.invalidCount++;
                    }

                    behaviorsMatrix[behavior - 1][evaluationResult.evaluation_type_id - 1] = item;
                });
            }

            for (let indexColumn = 0; indexColumn < EVALUATIONS_TYPE_COUNT; indexColumn++) {
                for (let indexRow = 0; indexRow < BEHAVIORS_COUNT; indexRow++) {
                    const item = behaviorsMatrix[indexRow][indexColumn];
                    behaviorsMatrixAvg[indexRow][indexColumn] = item?.validCount
                        ? numberHelper.round(item.sum / item?.validCount)
                        : 0;
                }
            }

            for (let indexColumn = 0; indexColumn < EVALUATIONS_TYPE_COUNT; indexColumn++) {
                for (let indexRow = 0; indexRow < ATTRIBUTES_COUNT; indexRow++) {
                    const b1 = behaviorsMatrixAvg[indexRow * 3][indexColumn];
                    const b2 = behaviorsMatrixAvg[indexRow * 3 + 1][indexColumn];
                    const b3 = behaviorsMatrixAvg[indexRow * 3 + 2][indexColumn];

                    const behaviors = [b1, b2, b3];
                    let sum = 0;
                    let count = 0;

                    for (let index = 0; index < behaviors.length; index++) {
                        const behavior = behaviors[index];

                        if (behavior) {
                            sum += behavior;
                            count++;
                        }
                    }

                    attributesAvgMatrix[indexRow][indexColumn] = count ? numberHelper.round(sum / count) : 0;
                }
            }

            return {
                behaviorsMatrix,
                behaviorsMatrixAvg,
                matrixAvg: attributesAvgMatrix,
                evaluationTypesExistIdMap
            };
        }
    },
    mutations: {
        setUserEvaluations(state, { userId, companyId, gameId, data }) {
            state.userEvaluations = { userId, companyId, gameId, data };
        }
    },
    actions: {
        async loadUserEvaluations({ commit, state }, { userId, companyId, gameId }) {
            try {
                const response = await EvaluationsAPIService.getResults({
                    userId,
                    companyId,
                    gameId
                });

                const data = response.data?.userEvaluations ?? [];
                commit('setUserEvaluations', { userId, companyId, gameId, data });

                return data;
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data.error); // "invalid_credentials"
                    console.log(error.response.status);
                }
            }
        }
    }
};
